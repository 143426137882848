import { useController } from "react-hook-form"
import {
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  Tooltip,
  Fade,
  Box,
} from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1.25),
    display: "inline-flex",
  },
  label: {
    background: theme.palette.white,
    minWidth: "90px",
    textAlign: "center",
  },
  select: { width: "230px" },
  fullWidthSelect: { width: "100%" },
}))

const Select = ({
  data,
  name,
  controller,
  label,
  labelId,
  disabled,
  availabilityTypeSelect,
  issueTypeSelect,
  fullWidth,
  tooltipText,
  valueWithTooltip,
}) => {
  const classes = useStyles()
  const { field, fieldState } = useController({
    name,
    control: controller,
  })
  const { onChange, ...rest } = field || {}
  const error = fieldState?.error
  const helperText = error?.message
  const invalid = !!error?.message

  return (
    <FormControl className={classes.root} variant="outlined">
      <InputLabel id={labelId} className={classes.label}>
        {label}
      </InputLabel>
      <MuiSelect
        {...rest}
        labelId={labelId}
        className={fullWidth ? classes.fullWidthSelect : classes.select}
        disabled={disabled}
        onChange={(newValue) => onChange(newValue)}
        {...field}
      >
        {availabilityTypeSelect || issueTypeSelect
          ? data.map(([key, value]) =>
              key === valueWithTooltip ? (
                <MenuItem key={key} value={value}>
                  <Tooltip
                    title={tooltipText}
                    placement="bottom-start"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 100 }}
                  >
                    <Box sx={{ width: "100%" }}>{key}</Box>
                  </Tooltip>
                </MenuItem>
              ) : (
                <MenuItem key={key} value={value}>
                  {key}
                </MenuItem>
              )
            )
          : data.map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
      </MuiSelect>
      {invalid && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default Select
