import React, {
  useLayoutEffect,
  useState,
  useRef,
  useEffect,
  useMemo,
} from "react"
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import {
  Box,
  AppBar,
  Toolbar,
  Drawer,
  IconButton,
  Button,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Backdrop,
  Container,
  Menu,
  MenuItem,
  Badge,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Videocam as VideocamIcon,
  FeedbackOutlined as FeedbackOutlinedIcon,
  Feedback as FeedbackFilledIcon,
} from "@mui/icons-material/"
import config from "config"
import { fetchCalendarEvents, fetchKaddioForms } from "redux/actions"
import { Logo } from "assets"
import { addMinutes } from "date-fns"
import MeetingDialog from "components/MeetingDialog"
import MeetingRoomDialog from "components/MeetingRoomDialog"
import LeaveMeetingConfirmationDialog from "components/LeaveMeetingConfirmationDialog"
import { getCaregiverTimeSlotsAvailable } from "api/getCaregiverTimeSlotsAvailable"
import { getFormattedDateAndTime } from "utils/getFormattedDateAndTime"
import Tooltip from "@mui/material/Tooltip"
import { logout } from "utils/logout"
import DefaultAvatar from "./DefaultAvatar"
import BurgerMenuIcon from "./BurgerMenuIcon"
import Banner from "./Banner"

const COMPANY_NAME = config.company_name
const DRAWER_WIDTH = "80%"
const CURRENT_YEAR = new Date().getFullYear()
const AVAILABLE_SLOTS_FOR_BANNER_DISPLAY = [0, 1, 2, 3]

const menuItems = [
  {
    key: "ikbt-overview",
    text: "IKBT Översikt",
    route: "/ikbt-overview",
    routeRedirect: true,
    access: (user) => user.ikbtOverviewAccess,
  },
  {
    key: "messages",
    text: "Meddelanden",
    route: "/messages",
    routeRedirect: true,
  },
  {
    key: "sessions",
    text: "Journalföring",
    route: "/sessions",
    routeRedirect: true,
  },
  {
    key: "calendar",
    text: "Kalender",
    route: "/",
    routeRedirect: true,
  },
]

const useWindowHeight = () => {
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    function updateHeight() {
      setHeight(window.innerHeight)
    }
    window.addEventListener("resize", updateHeight)
    updateHeight()
    return () => window.removeEventListener("resize", updateHeight)
  }, [])
  // preventing unnecessary scroll being displayed on some screen resolutions
  return height - 0.1
}

const useStyles = makeStyles((theme) => ({
  root: (height) => ({
    height,
    position: "relative",
    display: "flex",
    flexDirection: "column",
  }),
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    height: 64,
  },
  titleContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-start",
    },
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "150px",
    },
  },
  drawer: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    top: "64px",
  },
  displayName: {
    color: theme.palette.black,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
  },
  menuPaper: {
    borderRadius: 0,
  },
  menuItem: {
    color: theme.palette.black,
    lineHeight: "36px",
  },
  menuDivider: {
    margin: "0 8px",
    backgroundColor: theme.palette.light_gray,
  },
  badge: {
    "& .MuiBadge-badge": {
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
      height: "17px",
      minWidth: "17px",
      width: "17px",
      color: theme.palette.white,
      backgroundColor: theme.palette.error.main,
      fontWeight: 600,
      fontSize: "0.6rem",
      paddingTop: theme.spacing(0.0625),
      paddingLeft: theme.spacing(0.8125),
    },
    "&.mobile .MuiBadge-badge": {
      top: theme.spacing(1.5),
      right: theme.spacing(1),
    },
  },
  videoButton: {
    textTransform: "none",
    textDecoration: "underline",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "0.89rem",
    color: theme.palette.primary.main,
    "&:hover, &:active, &:focus": {
      background: "none",
      textDecoration: "underline",
    },
    [theme.breakpoints.up("md")]: {
      "& svg": {
        marginRight: theme.spacing(1),
      },
    },
    [theme.breakpoints.down("md")]: {
      "& .MuiListItemIcon-root": {
        minWidth: theme.spacing(4),
        color: "inherit",
      },
      "& .MuiListItemText-root > *": {
        fontWeight: "inherit",
        color: "inherit",
      },
    },
  },
  feedbackIcon: {
    fontSize: "1.2rem",
    marginLeft: theme.spacing(0.5),
    verticalAlign: "middle",
    color: "#FF7A00",
  },
  tooltip: {
    backgroundColor: "rgba(60, 58, 63, 0.95)",
    borderRadius: "4px",
    color: "#F5EFF7",
    fontSize: "0.875rem",
  },
}))

const toggleDrawer = (isOpen, setOpen) => (event) => {
  if (
    event.type === "keydown" &&
    (event.key === "Tab" || event.key === "Shift")
  ) {
    return
  }

  setOpen(isOpen)
}

const ListItemLink = (props) => {
  const {
    icon,
    primary,
    to,
    routeRedirect,
    route,
    videoMeetingFormattedStart,
    onVideoMeetingLinkClick,
    className,
    setOpen,
  } = props
  const navigate = useNavigate()

  const handleNavigation = () => {
    if (videoMeetingFormattedStart) {
      onVideoMeetingLinkClick()
    } else if (routeRedirect) {
      navigate(route)
    } else {
      window.open(to, "_blank")
    }
    setOpen(false)
  }

  return (
    <ListItem onClick={() => handleNavigation()} className={className}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primary} />
    </ListItem>
  )
}

const RenderDrawer = ({
  user,
  isOpen,
  setOpen,
  unreadMessagesCount,
  videoMeetingFormattedStart,
  onVideoMeetingLinkClick,
  ...rest
}) => {
  const classes = useStyles()

  const menuItemsWithAccess = useMemo(
    () =>
      menuItems.filter((item) => {
        if (item.access) {
          return item.access(user)
        }

        return true
      }),
    [user]
  )

  return (
    <Drawer
      variant="persistent"
      open={isOpen}
      onClose={toggleDrawer(false, setOpen)}
      {...rest}
    >
      <List>
        {videoMeetingFormattedStart && (
          <ListItemLink
            primary={videoMeetingFormattedStart}
            icon={<VideocamIcon />}
            className={classes.videoButton}
            sameWindow
            videoMeetingFormattedStart={videoMeetingFormattedStart}
            onVideoMeetingLinkClick={onVideoMeetingLinkClick}
            setOpen={setOpen}
          />
        )}
        {menuItemsWithAccess.map((item) =>
          item.key === "messages" ? (
            <Badge
              badgeContent={unreadMessagesCount}
              className={`${classes.badge} mobile`}
            >
              <ListItemLink
                key={item.key}
                route={item.route}
                to={config[item.key]}
                routeRedirect={item.routeRedirect}
                primary={item.text}
                setOpen={setOpen}
              />
            </Badge>
          ) : (
            <ListItemLink
              key={item.key}
              route={item.route}
              to={config[item.key]}
              routeRedirect={item.routeRedirect}
              primary={item.text}
              setOpen={setOpen}
            />
          )
        )}
        <Divider />
        <ListItem>
          <Typography variant="caption">
            &#169; {COMPANY_NAME} {CURRENT_YEAR}
          </Typography>
        </ListItem>
      </List>
    </Drawer>
  )
}

const Header = ({
  children,
  user,
  chats,
  calendarEvents,
  kaddioForms,
  dispatch,
  ...rest
}) => {
  const height = useWindowHeight()
  const classes = useStyles(height)
  const [isOpen, setOpen] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [nextBooking, setNextBooking] = useState(null)
  const [meetingRoomData, setMeetingRoomData] = useState(null)
  const [openMeetingDialog, setOpenMeetingDialog] = useState(false)
  const [availableSlots, setAvailableSlots] = useState(null)
  const [openMeetingRoomDialog, setOpenMeetingRoomDialog] = useState(false)
  const [
    openLeaveMeetingConfirmationDialog,
    setOpenLeaveMeetingConfirmationDialog,
  ] = useState(false)
  const { avatarUrl, firstName, lastName, userId } = user
  const { activeUnreadMessagesCount, inactiveUnreadMessagesCount } = chats
  const unreadMessagesCount =
    activeUnreadMessagesCount + inactiveUnreadMessagesCount
  const iconButtonRef = useRef()
  const navigate = useNavigate()
  const videoMeetingFormattedStart = nextBooking
    ? `Kommande videosamtal: ${getFormattedDateAndTime(nextBooking.start)}`
    : null

  const checkAvailableSlots = async () => {
    const { data } = await getCaregiverTimeSlotsAvailable(userId)
    setAvailableSlots(data?.available?.length)
  }

  useEffect(() => {
    dispatch(fetchCalendarEvents(userId))
    if (!kaddioForms) {
      dispatch(fetchKaddioForms())
    }
  }, [dispatch])

  useEffect(() => {
    if (calendarEvents?.bookings) {
      const futurePaidEvents = calendarEvents.bookings.filter(
        (event) =>
          event.isSuccessfulPayment && addMinutes(event.start, 24) > new Date()
      )
      futurePaidEvents.sort(
        (startTimeFirst, startTimeSecond) =>
          startTimeFirst.start - startTimeSecond.start
      )
      setNextBooking(futurePaidEvents[0])
    }
  }, [calendarEvents?.bookings])

  useEffect(() => {
    checkAvailableSlots()
  }, [calendarEvents?.bookings, calendarEvents?.available])

  const handleJoinMeeting = (meetingInfo) => {
    setMeetingRoomData(meetingInfo)
    setOpenMeetingDialog(false)
    setOpenMeetingRoomDialog(true)
  }

  const getSessionIcon = (sessionCount) => {
    if (sessionCount > 15) {
      return <FeedbackFilledIcon className={classes.feedbackIcon} />
    }
    if (sessionCount > 9) {
      return <FeedbackOutlinedIcon className={classes.feedbackIcon} />
    }
    return null
  }

  const getToolTipMessage = (sessionCount) => {
    if (sessionCount > 15) {
      return `Maximal behandlingslängd (15 sessioner för video, 19 sessioner för IKBT med video) har uppnåtts.
       Avsluta behandlingen.`
    }
    if (sessionCount > 9) {
      return `Patient uppnår behandlingslängd på 10 sessioner. Bedöm om patient haft effekt av behandlingen.
       Maximal behandlingslängd är 15 sessioner för video, och 19 sessioner för IKBT med video.`
    }
    return ""
  }

  const menuItemsWithAccess = useMemo(
    () =>
      menuItems.filter((item) => {
        if (item.access) {
          return item.access(user)
        }

        return true
      }),
    [user]
  )

  const renderDesktopMenuItems = () => (
    <>
      {videoMeetingFormattedStart && (
        <Button
          disableRipple
          className={classes.videoButton}
          onClick={() => setOpenMeetingDialog(true)}
        >
          <VideocamIcon />
          {videoMeetingFormattedStart}
          {nextBooking.patientTotalSessionNumber > 9 && (
            <Tooltip
              title={getToolTipMessage(nextBooking.patientTotalSessionNumber)}
            >
              {getSessionIcon(nextBooking.patientTotalSessionNumber)}
            </Tooltip>
          )}
        </Button>
      )}
      {menuItemsWithAccess.map((item) =>
        item.key === "messages" ? (
          <Badge badgeContent={unreadMessagesCount} className={classes.badge}>
            <Button
              color="default"
              key={item.key}
              onClick={() =>
                item.routeRedirect
                  ? navigate(item.route)
                  : window.open(config[item.key], "_blank")
              }
            >
              {item.text}
            </Button>
          </Badge>
        ) : (
          <Button
            color="default"
            key={item.key}
            onClick={() =>
              item.routeRedirect
                ? navigate(item.route)
                : window.open(config[item.key], "_blank")
            }
          >
            {item.text}
          </Button>
        )
      )}
    </>
  )

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Hidden lgUp>
              {isOpen ? (
                <IconButton onClick={toggleDrawer(!isOpen, setOpen)}>
                  <BurgerMenuIcon open={isOpen} />
                </IconButton>
              ) : (
                <Badge
                  badgeContent={unreadMessagesCount}
                  className={classes.badge}
                >
                  <IconButton onClick={toggleDrawer(!isOpen, setOpen)}>
                    <BurgerMenuIcon open={isOpen} />
                  </IconButton>
                </Badge>
              )}
            </Hidden>

            <Box className={classes.titleContainer}>
              <Logo
                className={classes.logo}
                onClick={() => window.open(config.web_site, "_blank")}
              />
            </Box>

            <Hidden lgDown>{renderDesktopMenuItems()}</Hidden>

            <IconButton onClick={() => setMenuOpen(true)} ref={iconButtonRef}>
              <DefaultAvatar
                src={avatarUrl}
                name={`${firstName} ${lastName}`}
              />
              {isMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Toolbar>
        </AppBar>

        <Menu
          classes={{ paper: classes.menuPaper }}
          elevation={3}
          anchorEl={iconButtonRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={isMenuOpen}
          onClose={() => setMenuOpen(false)}
        >
          <MenuItem className={classes.menuItem} component={Link} to="/profile">
            Min Profil
          </MenuItem>
          <Divider className={classes.menuDivider} />
          <MenuItem
            className={classes.menuItem}
            onClick={() => window.open(config.support, "_blank")}
          >
            Support
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => window.open(config.videoSchoolUrl, "_blank")}
          >
            Videoskola
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => window.open(config.ikbtSchoolUrl, "_blank")}
          >
            IKBT-skola
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => window.open(config.guideUrl, "_blank")}
          >
            Psykologguide
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => window.open(config.braiveUrl, "_blank")}
          >
            Braive
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => window.open(config.kaddioUrl, "_blank")}
          >
            Kaddio
          </MenuItem>
          <Divider className={classes.menuDivider} />
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              logout(dispatch)
              setMenuOpen(false)
            }}
          >
            Logga ut
          </MenuItem>
        </Menu>

        <RenderDrawer
          className={classes.Drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          user={user}
          isOpen={isOpen}
          setOpen={setOpen}
          videoMeetingFormattedStart={videoMeetingFormattedStart}
          unreadMessagesCount={unreadMessagesCount}
          onVideoMeetingLinkClick={() => {
            setOpenMeetingDialog(true)
          }}
        />
        <Backdrop
          className={classes.backdrop}
          open={isOpen}
          onClick={toggleDrawer(false, setOpen)}
        />
      </div>
      {AVAILABLE_SLOTS_FOR_BANNER_DISPLAY.includes(availableSlots) && (
        <Banner
          availableSlots={availableSlots}
          caregiverFirstName={firstName}
        />
      )}
      <Container maxWidth="lg" disableGutters className={classes.container}>
        {children}
      </Container>
      <MeetingDialog
        openDialog={openMeetingDialog}
        onClose={() => setOpenMeetingDialog(false)}
        meeting={nextBooking}
        userId={userId}
        onJoinMeeting={handleJoinMeeting}
      />
      <MeetingRoomDialog
        openDialog={openMeetingRoomDialog}
        onClose={() => setOpenLeaveMeetingConfirmationDialog(true)}
        meetingRoomUrl={meetingRoomData?.resource}
        meetingStart={meetingRoomData?.start}
        patientId={meetingRoomData?.patientId}
        patientFullName={meetingRoomData?.title}
        patientKaddioId={meetingRoomData?.patientKaddioId}
        patientKaddioContactId={meetingRoomData?.visitId}
        patientEmail={meetingRoomData?.patientEmail}
        patientVisitNumber={meetingRoomData?.patientTotalSessionNumber}
        patientIsSormland={meetingRoomData?.patientIsSormland}
        patientSocialSecurity={meetingRoomData?.patientPnr}
        patientGender={meetingRoomData?.patientGender}
        patientZipCode={meetingRoomData?.patientZipCode}
        sessionId={meetingRoomData?.sessionId}
        chatId={meetingRoomData?.chatId}
        pastSessionsCount={nextBooking?.patientTotalSessionNumber}
        meetingId={meetingRoomData?.id}
      />
      <LeaveMeetingConfirmationDialog
        openDialog={openLeaveMeetingConfirmationDialog}
        onClose={() => {
          setOpenLeaveMeetingConfirmationDialog(false)
        }}
        onConfirm={() => {
          dispatch(fetchCalendarEvents(userId))
          setMeetingRoomData(null)
          setOpenMeetingRoomDialog(false)
          setOpenLeaveMeetingConfirmationDialog(false)
        }}
      />
    </div>
  )
}

const mapStateToProps = ({ user, calendarEvents, kaddioForms, chats }) => ({
  user,
  calendarEvents,
  kaddioForms,
  chats,
})

export default connect(mapStateToProps)(Header)
